import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'

const BlogRoll = ({ data }) => {
  const { allMarkdownRemark: { edges } } = data;

  const getColorData = (category) => {
    let value = typeof category === 'string' ? category : category[0];
    if (value === 'Company Update') return 'bg-red-100 text-red-800';
    if (value === 'Case Study') return 'bg-blue-100 text-blue-800';
    if (value === 'Engineering') return 'bg-gray-200 text-gray-800';
    if (value === 'Customer Experience') return 'bg-green-100 text-green-800';
    if (value === 'Feature') return 'bg-yellow-100 text-yellow-800';
    if (value === 'Opinion') return 'bg-purple-100 text-purple-800';
    return 'bg-indigo-100 text-indigo-800';
  }

  return (
    <div className="mt-5 mx-5 lg:mx-0 mb-10 grid gap-16 border-t-2 border-gray-100 pt-12 lg:grid-cols-3 lg:col-gap-12 lg:row-gap-12">
      {edges && edges.map(({ node: post }) => (
        <div>
          <div>
            <span className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 ${getColorData(post.frontmatter.category)}`}>
              {post.frontmatter.category}
            </span>
          </div>
          <Link
            className="flex-1"
            to={post.fields.slug}
          >
            <h3 className="mt-4 text-xl leading-7 font-semibold text-gray-900">
              {post.frontmatter.title}
            </h3>
            <p className="mt-3 text-base leading-6 text-gray-800">
              {post.excerpt}
            </p>
          </Link>
          <div className="mt-6 flex items-center">
            <div>
              <p className="text-sm leading-5 font-medium text-gray-900">
                {post.frontmatter.author}
              </p>
              <div className="flex text-sm leading-5 text-gray-900">
                <time datetime="2020-03-16">
                  {post.frontmatter.date}
                </time>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default ({ featured, tags }) => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 150)
              id
              fields {
                slug
              }
              frontmatter {
                title
                author
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                category
                tags
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll tags={tags} featured={featured} data={data} count={count} />}
  />
)
