import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Firebase from "gatsby-plugin-firebase"
import React from 'react'

import PageContentWrapper from '../components/PageContentWrapper';
import BlogRoll from '../components/BlogRoll'
import Layout from '../components/Layout'
import { useState } from 'react';


export const IndexPageTemplate = ({
  heading,
  subheading,
}) => {
  const [email, setEmail] = useState('');
  const [showSignupModal, setShowSignupModal] = useState(false);
  return (
    <>
      {
        showSignupModal &&
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <svg className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                    Signup Successful
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm leading-5 text-gray-500">
                      Look for us in your inbox soon!
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <span className="flex w-full rounded-md shadow-sm">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    onClick={() => setShowSignupModal(false)}
                  >
                    Back to site
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      }
      <PageContentWrapper>
        <div className="relative bg-white overflow-hidden">
          <div className="relative pt-6">
            <main className="px-5 md:px-0 mt-8 mx-5 mx-auto max-w-screen-xl sm:mt-12 xl:mt-12">
              <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left flex flex-col">
                  <p className="text-base leading-6 text-blue-900 font-semibold tracking-wide uppercase">
                    The Column Blog
                  </p>
                  <h2 className="mt-3 text-4xl md:text-5xl tracking-tight leading-12 font-medium text-gray-900 sm:leading-none">
                    {heading}
                  </h2>
                  <p className="mt-5 text-lg leading-7 text-gray-800 flex-1 items-center flex">
                    <span>
                      {subheading}
                    </span>
                  </p>
                  <div className="mt-5 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
                    <p className="text-base font-medium text-gray-900">
                      Sign up to get our latest content straight to your inbox
                    </p>
                    <form
                      className="mt-3 sm:flex"
                      onSubmit={e => {
                        e.preventDefault();
                        const slackCustomMessage = Firebase.functions().httpsCallable('slackCustomMessage');
                        slackCustomMessage(`Newsletter signup from: ${email} from blog`);
                        setEmail('');
                        setShowSignupModal(true);
                      }}
                    >
                      <input
                        aria-label="Email" className="appearance-none block w-full px-3 py-3 border border-gray-300 text-base leading-6 rounded-md placeholder-gray-500 shadow-sm focus:outline-none focus:placeholder-gray-400 focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:flex-1" placeholder="Enter your email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        type="email"
                      />
                      <button
                        type="submit"
                        className="mt-3 w-full px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white shadow-sm bg-blue-900 hover:bg-blue-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
                      >
                        Notify me
                      </button>
                    </form>
                  </div>
                </div>
                <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
                  <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
                    <button type="button" className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
                      <img className="w-full" src="https://firebasestorage.googleapis.com/v0/b/enotice-demo-8d99a.appspot.com/o/oneoff%2FLanding%2FF1AFEF62-F59A-4E97-8D08-82A63E63A276_1_105_c (1).jpeg?alt=media&token=f0795800-394a-41c7-a740-4337096ba27a" alt="Newspaper Cubbies" />
                    </button>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
        <BlogRoll />
    </PageContentWrapper>
    </>
  )
}

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        subheading
      }
    }
  }
`
