import React from 'react';

const PageContentWrapper = ({children}) => (
  <div style={{
    maxHeight: 'calc(100vh - 140px)',
    overflow: 'scroll'
  }}
  >
    <div className="max-w-screen-lg mx-auto">
      {children}
    </div>
  </div>
)

export default PageContentWrapper;